import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import moment from 'moment-timezone';

import environment from '@/.config/environment';

export const nowTime = () => Date.now();

export const nowDateString = () => Date();

export const isBeforeTimestamp = (classStartTimestamp) => moment().isAfter(classStartTimestamp);

export const adjustUnixTimestamp = (unixTimestamp) => unixTimestamp * 1000;

export const isEmpty = (obj) =>
    [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;

const { studentUI = {} } = environment(window);
export const getStudentURL = (classId, accessCode) =>
    `${studentUI.url}/class/${classId}` +
    (accessCode ? `?accessCode=${encodeURIComponent(accessCode)}` : '');

export const getCustomLandingPageURL = () => studentUI.customLandingPageUrl;

export const getFirstAccessCode = (classData) => {
    return get(classData, 'classroom.accessCodes[0].accessCode');
};

export const openTab = (url) => window.open(url, '_blank', 'noopener');

const sanitizeClipboardValue = (value) => {
    if (value) {
        value = value.trim();
        if (value.indexOf('\n') >= 0) {
            // Append newline to multiline text as an application contention.
            // In lab sessions, multiline text often represents shell commands
            // that are intended to be executed in a terminal, where the trailing
            // newline executes the last one.
            value += '\n';
        }
    }
    return value;
};

export const copyToClipboard = ({ globals = window, value, onSuccess }) =>
    globals.navigator.clipboard
        .writeText(sanitizeClipboardValue(value))
        .then(typeof onSuccess === 'function' ? onSuccess : () => {});

export const getCurrentUser = async () =>
    (await Auth.currentAuthenticatedUser({ bypassCache: true })).getSignInUserSession().getIdToken()
        .payload;

export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

/**
 * @param {Number} startsOn Start date/time in epoch format
 * @param {Number} endsOn End date/time in epoch format
 * @param {String} locale Self-explanatory, you should get this value from React-Intl
 * @return {String} Duration string in either days, hours, or minutes
 *
 * @note There is an assumption that all classes will be longer than 1 minute in duration
 */
export const getDuration = (startsOn, endsOn, locale) => {
    const start = moment.unix(startsOn);
    const end = moment.unix(endsOn);

    return ['minutes', 'hours', 'days']
        .map((u) => {
            const diff = Math.ceil(end.diff(start, u, true));
            if (diff > 1) {
                const dur = moment.duration(diff, u);
                return dur.locale(locale).humanize();
            }
            return null;
        })
        .filter((v) => v)
        .pop();
};

/**
 * @typedef {Object} RetryOptions
 * @property {Number} [retries=3]
 * @property {Number} [interval=100]
 * @property {Boolean} [expBackoff=true] Adds exponential backoff between retries
 * @property {Boolean} [jitter=true] Adds jitter to retries
 * @property {Function<Boolean>} [retryPredicate] Fine tune when to retry, gets passed the caught error.
 */

/** @type {RetryOptions} */
const defaultOptions = {
    retries: 3,
    interval: 100,
    expBackoff: true,
    jitter: true,
};

/**
 * @param {Number} attempt
 * @param {RetryOptions} [options]
 */
export const calculateRetryDelay = (attempt, options = {}) => {
    const opts = { ...defaultOptions, ...options };
    // Avoid delays when running test suite.
    if (process.env.NODE_ENV === 'test') return 0;

    const expBackoff = opts.expBackoff ? Math.pow(2, attempt) : 1;
    const jitter = opts.jitter ? Math.random() : 1;
    return Math.floor(opts.interval * jitter * expBackoff);
};

export const downloadPresignUrl = (presignUrl) => {
    const a = document.createElement('a');
    a.setAttribute('target', '_blank');
    a.setAttribute('href', presignUrl);
    a.setAttribute('download', '');
    a.setAttribute('style', 'display: none;');

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const sortCourseVersions = (courseVersions) => {
    const comparableVersionId = (courseVersionId) => {
        const smallestVersionId = [0, 0, 0];
        if (!courseVersionId.includes('-')) {
            return smallestVersionId;
        }
        const versionIdString = courseVersionId.split('-')[0];
        if (!versionIdString.includes('.')) {
            return smallestVersionId;
        }
        const versionIdArray = versionIdString.split('.');
        if (versionIdArray.length !== 3) {
            return smallestVersionId;
        }
        return versionIdArray;
    };

    return courseVersions.sort((a, b) => {
        const aComp = comparableVersionId(a.versionId);
        const bComp = comparableVersionId(b.versionId);
        return (
            bComp[0] - aComp[0] ||
            bComp[1] - aComp[1] ||
            bComp[2] - aComp[2] ||
            b.createdOn - a.createdOn
        );
    });
};

const findStringIntersection = (labTitle, courseTitle) => {
    let buffer = '';
    for (let i = 0; i < courseTitle.length; i++) {
        if (labTitle[i] === courseTitle[i]) {
            buffer += labTitle[i];
        } else break;
    }
    return buffer.trim();
};

/**
 * ("course title - lab x - something", "course title") => "lab x - something"
 * ("lab x - something", "course title") => "lab x - something"
 * ("course title - lab x - something", "course title (en español)") => "lab x - something"
 * @param {string} title
 * @param {string} courseTitle
 * @returns String
 */
export const removeCourseNameFromLabTitle = (title, courseTitle) => {
    if (!title) return '';
    if (!courseTitle) return title;

    try {
        // find intersection between lab title and course title
        const intersection = findStringIntersection(title.trim(), courseTitle.trim());
        // remove intersecting string from title
        if (intersection.length >= title || intersection === 'AWS' || intersection.length <= 1) {
            return title;
        }
        const titleRegex = new RegExp(`${intersection}\\s*[-:]?\\s*`, 'i');
        return title.replace(titleRegex, '').trim();
    } catch (error) {
        return title;
    }
};

/**
 * Method to calculate the actual reserved licenses for this class (under this provider).
 * This also counts in the once-enrolled-later-removed students.
 * @param {Object[]} studentRoster - The student roster for this class (under this provider).
 * @param {number} [studentCount=0] - The number of students who have accepted invitation from this class (under this provider).
 * @returns number - The number of the total reserved licences for this class (under this provider).
 */
export const calculateReservedLicenses = (studentRoster, studentCount = 0) => {
    if (!studentRoster || studentRoster.length === 0) {
        // In this case, all the reserved licences are for once-enrolled-later-removed students.
        return studentCount;
    }
    const numPendingStudent = studentRoster.filter(
        (student) => !student.hasAcceptedInvitation,
    ).length;
    return numPendingStudent + studentCount;
};
