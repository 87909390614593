import {
    Box,
    Button,
    Container,
    ContentLayout,
    Header,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { get as _get } from 'lodash';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { LabNavigation, IngressTable, ManageClassDropdown } from '@/components';
import { CLASS_ACCESS_TYPES } from '@/components/classForm/ClassForm.utils';
import { Layout } from '@/containers/Layout';
import { useClassroomData } from '@/data/useClassroomData';
import { getStudentTrainingsByClassroomId } from '@/graphql/queries';
import { executeRequest } from '@/modules/api';
import { removeCourseNameFromLabTitle } from '@/utils';
import { classDetailPageBreadcrumb, classesPageBreadcrumb } from '@/utils/breadcrumbs';
import { useFlags } from '@/utils/flags';

import messages from './Lab.messages';

const { labTitle, somethingWentWrongTrainings, close } = messages;

const errorMessages = {
    'getStudentTrainingsByClassroomId::403': messages.getStudentTrainingsByClassroomId403Error,
    default: messages.cannotGetStudentLabs,
};

const A_MINUTE = 60000;

const useQueryOptions = {
    refetchOnWindowFocus: false,
    refetchInterval: A_MINUTE,
};

const Lab = () => {
    const params = useParams();
    const { classroomId: encodedClassroomId } = params;
    const classroomId = encodedClassroomId ? decodeURIComponent(encodedClassroomId) : undefined;
    const labNumber = parseInt(params.labNumber);
    const { formatMessage } = useIntl();
    const { studentRoster } = useFlags();
    const translatedLabTitle = formatMessage(labTitle);
    const { classData } = useClassroomData(classroomId);
    const { content, classroom } = classData;
    const currentLab = _get(content, `[${labNumber - 1}]`, {});
    const labId = currentLab.contentId;
    const [getTrainingsError, getTrainingsErrorSet] = useState(false);
    const hasStudentRoster =
        studentRoster && classData?.classroom?.accessType === CLASS_ACCESS_TYPES.roster;

    const queryParams = {
        classroomId,
        accessType: classData?.classroom?.accessType,
    };
    if (hasStudentRoster) {
        queryParams.accessType = CLASS_ACCESS_TYPES.roster;
    }

    const { data, isLoading, isFetching, refetch, error } = useQuery(
        [classroomId, queryParams],
        () =>
            executeRequest({
                operation: getStudentTrainingsByClassroomId,
                params: queryParams,
            }),
        {
            ...useQueryOptions,
            enabled: !!classData?.classroom,
        },
    );

    useEffect(() => {
        if (error) {
            const msg =
                errorMessages[`${error.path}::${error.statusCode}`] || errorMessages.default;
            getTrainingsErrorSet(msg);
        }
    }, [error]);

    /**
     * Currently we are getting all training from the activity service.
     * This has some complications.
     *
     * 1. A training record can reference old lab arns
     * 2. A training record may be in an error state
     * 3. We do not keep state, each student has more than one "active" lab, this is because
     * we are not onboarded to the labs hook notification. https://sim.amazon.com/issues/BKR-2236
     */

    const studentTrainingsByCurrentLabId =
        !isLoading &&
        !getTrainingsError &&
        data &&
        data.getStudentTrainingsByClassroomId.studentTrainings;

    const courseTitle = classData?.course?.title;
    return (
        <Layout
            breadcrumbItems={[
                classesPageBreadcrumb(formatMessage),
                classDetailPageBreadcrumb(formatMessage, classroomId),
                { text: formatMessage(messages.sectionTitle) },
            ]}
            navigation={<LabNavigation content={content} courseTitle={courseTitle} />}
        >
            <Helmet>
                <title>
                    {formatMessage(messages.pageTitle)} - {`${translatedLabTitle} ${labNumber}`}
                </title>
            </Helmet>
            <ContentLayout
                disableOverlap
                disablePadding
                header={
                    <SpaceBetween size='m'>
                        <Header variant='h1'>{courseTitle}</Header>
                    </SpaceBetween>
                }
            >
                <Container
                    header={
                        <Header
                            variant='h2'
                            description={formatMessage(messages.sectionSubheader)}
                            actions={<ManageClassDropdown />}
                        >
                            {removeCourseNameFromLabTitle(currentLab?.title, courseTitle)}
                        </Header>
                    }
                >
                    <IngressTable
                        labId={labId}
                        loading={isLoading}
                        langLocale={classroom?.langLocale}
                        studentTrainings={
                            studentTrainingsByCurrentLabId &&
                            studentTrainingsByCurrentLabId.length > 0
                                ? studentTrainingsByCurrentLabId
                                : []
                        }
                        trainingRefetch={refetch}
                        trainingLoading={isFetching}
                        hasStudentRoster={hasStudentRoster}
                    />
                </Container>
            </ContentLayout>

            {getTrainingsError ? (
                <Modal
                    visible
                    onDismiss={() => getTrainingsErrorSet(false)}
                    header={formatMessage(somethingWentWrongTrainings)}
                    closeAriaLabel={formatMessage(messages.closeModalLabelText)}
                    footer={
                        <Box float='right'>
                            <Button
                                onClick={() => getTrainingsErrorSet(false)}
                                variant='primary'
                                data-testid='lab-training-errors-modal__close-btn'
                            >
                                {formatMessage(close)}
                            </Button>
                        </Box>
                    }
                >
                    {formatMessage(getTrainingsError)}
                </Modal>
            ) : null}
        </Layout>
    );
};

export default Lab;
