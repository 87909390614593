import { defineMessages } from 'react-intl.macro';

const labMessages = defineMessages({
    pageTitle: {
        id: 'LabPage.pageTitle',
        description: 'Lab page title',
        defaultMessage: 'Lab details - AWS Training and Certification',
    },
    sectionTitle: {
        id: 'LabPage.sectionTitle',
        description: 'Title above IngressTable',
        defaultMessage: 'Manage labs',
    },
    sectionSubheader: {
        id: 'LabPage.sectionSubheader',
        defaultMessage: 'Manage, pre-load, and modify labs for students here.',
    },
    tableHeaderStudent: {
        id: 'lab.header.student',
        defaultMessage: 'Student',
    },
    noTrainings: {
        id: 'lab.noTrainings',
        defaultMessage: 'No trainings for lab',
    },
    tableHeaderLabStatus: {
        id: 'lab.header.labStatus',
        defaultMessage: 'Lab Status',
    },
    tableHeaderRegion: {
        id: 'lab.header.region',
        defaultMessage: 'Region',
    },
    tableHeaderControls: {
        id: 'lab.header.controls',
        defaultMessage: 'Access Controls',
    },
    openNavigation: {
        id: 'lab.navigation.open',
        defaultMessage: 'Open navigation',
    },
    closeNavigation: {
        id: 'lab.navigation.close',
        defaultMessage: 'Close navigation',
    },
    somethingWentWrongTrainings: {
        id: 'lab.modal.somethingWentWrongTrainings',
        defaultMessage: 'Something went wrong',
    },
    cannotGetStudentLabs: {
        id: 'lab.modal.cannotGetStudentLabs',
        defaultMessage: 'Cannot get the student labs',
    },
    getStudentTrainingsByClassroomId403Error: {
        id: 'lab.modal.getStudentTrainings403',
        defaultMessage: 'You do not have access to get student labs',
    },
    close: {
        id: 'lab.modal.cannotGetStudentLabs.close',
        defaultMessage: 'Close',
    },
    closeModalLabelText: {
        id: 'lab.modal.cannotGetStudentLabs.closeLabelText',
        defaultMessage: 'Close dialog',
    },
    labTitle: {
        id: 'lab.title',
        defaultMessage: 'Lab',
    },
});

export default labMessages;
